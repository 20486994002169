<template>
  <div class="container px-2 px-md-5">
    <div
      ref="iframeWrapper"
      class="
        position-relative
        mt-4
        shadow-card
        rounded
        bg-white
        intake-container
      "
    >
      <span @click="toggleFullscreen" v-if="intakeUrl">
        <icon
          type="expand-arrows-alt"
          class="fullscreen-element expand"
          v-show="!isFullscreen"
        />
        <icon
          type="compress-arrows-alt"
          class="fullscreen-element compress"
          v-show="isFullscreen"
        />
      </span>
      <iframe
        v-if="intakeUrl"
        width="100%"
        height="100%"
        :src="intakeUrl"
        frameborder="0"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
      >
        Your browser doesn't support iframes
      </iframe>
      <div
        class="d-flex justify-content-center align-items-center h-100 w-100 p-3"
      >
        <i
          class="fas fa-spin fa-circle-notch fa-2x"
          v-if="createIntakeLoading || waiting"
        ></i>
        <span v-if="!createIntakeLoading && !waiting" class="h3 text-center">
          No intake form active
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CreateIntakeForm",
  data() {
    return {
      createIntakeLoading: false,
      intakeUrl: "",
      intake: null,
      isFullscreen: false,
      waiting: false,
      formId: 0,
    };
  },
  created() {
    if (this.mkey) {
      this.completeIntakeHandler();
    }

    window.addEventListener("message", this.firsthx_iframe_message);
    document.addEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  destroyed() {
    window.removeEventListener("message", this.firsthx_iframe_message);
    document.removeEventListener("fullscreenchange", () => {
      document.fullscreenElement
        ? (this.isFullscreen = true)
        : (this.isFullscreen = false);
    });
  },
  computed: {
    mkey: function () {
      return this.$route.params.mkey;
    },
  },
  methods: {
    ...mapActions({
      getIntakeFormByKey: "intakeForms/getIntakeByKey",
      updateIntake: "intakeForms/update",
    }),
    completeIntakeHandler: async function () {
      this.createIntakeLoading = true;
      try {
        const data = await this.getIntakeFormByKey(this.mkey);
        this.intakeUrl = data?.form_url || "";
        this.formId = data?.id;
        this.intake = data;
        this.createIntakeLoading = false;
      } catch (error) {
        console.log(error);
        this.createIntakeLoading = false;
      }
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      const iframeWrapper = this.$refs.iframeWrapper;
      if (this.isFullscreen) {
        if (iframeWrapper.requestFullscreen) {
          iframeWrapper.requestFullscreen();
        } else if (iframeWrapper.mozRequestFullScreen) {
          iframeWrapper.mozRequestFullScreen(); // Firefox
        } else if (iframeWrapper.webkitRequestFullscreen) {
          iframeWrapper.webkitRequestFullscreen(); // Chrome, Safari, Opera
        } else if (iframeWrapper.msRequestFullscreen) {
          iframeWrapper.msRequestFullscreen(); // IE/Edge
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen(); // Chrome, Safari, Opera
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE/Edge
        }
      }
    },
    async firsthx_iframe_message(event) {
      if (event.data === "Activity" && this.intake?.status == "created") {
        const data = await this.updateIntake(this.intake.id);
        this.intake = data;
      }
      if (event.data === "Completed" || event.data === "IntakeEnded") {
        this.waiting = true;
        try {
          await this.updateIntake(this.formId);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intake-container {
  height: 80vh;
  background: hsla(186, 33%, 94%, 1);
  background: linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  overflow: hidden;
  .fullscreen-element {
    right: 8px;
    top: 8px;
    font-size: 2rem;
    color: #000;
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .expand {
    position: absolute !important;
  }
  .compress {
    position: fixed !important;
    z-index: 2147483647 !important;
  }
}
</style>
